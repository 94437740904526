import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  Form,
  Spinner,
  Row,
  Col,
  Stack,
  Modal,
  ListGroup,
  CloseButton,
  ProgressBar,
  Dropdown,
  DropdownButton,
  Image,
  Badge,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faGift,
  faCheck,
  faFilter,
  faSort,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { useToast } from "Provider/ToastProvider";
import { useUser } from "Provider/UserProvider";
import {
  check,
  fetchGiftInfo,
  requestGiftUrl,
  changeGift,
  fetchGiftAnonymous,
  fetchGiftInfoV2,
} from "./utils";
import { copyShare } from "Service/CopyBoardService";
import { LoadingSection } from "Components/LoaingPage";
import { EmptyPage } from "Components/EmptyPage";
import "./GiftPage.css";
import { OverflowText } from "Components/Text/OverflowText";
import { useErrorMsg } from "Components/ErrorMsg/ErrorMsg";
import {
  GiftPickForm,
  InvoiceForm,
  PayAmountDetail,
  PaymentProvider,
} from "Components/Payment/component";
import { useECPay } from "Hook/useECPay";
import {
  PlatformReviewList,
  ProductDetailSection,
  ProductSearchItem,
  ScrapeProductSearchResult,
  SpecSelector,
} from "Components/Product/component";
import {
  OrderRecord,
  OrderCardWithOption,
  OrderShortCard,
} from "Components/Order/ScrapeOrder";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  fetchProduct,
  scrapeProduct,
  searchProduct,
} from "Components/Product/utils";
import { useUrlQuery } from "Hook/useUrlQuery";
import { ProductSearchBar } from "User/Search/SearchPage";
import { useImgaeLoadObserver, useIO } from "Hook/useIntersectionObs";
import { MarkupNote, PaymentNote } from "Components/Note/PaymentNote";
import { calFee } from "Components/Payment/utils";
import { useDebounce } from "Hook/useDebounce";
import { StepProgress } from "Components/Progress/Progress";
import { GiftResChangeNote } from "Components/Note/Gift";
import { GiftSearchBar, SearchProductList } from "Components/Search/component";
import { GiftProvider, useGift } from "Provider/GiftProvider";
import { RefundNotice } from "Components/Note/Product";
import { OrderFAQ, ReceiverFAQ } from "Components/FAQ/FAQ";

const GIFTSTEPCHECK = "check";
const GIFTSTEPSEARCH = "search";
const GIFTSTEPSCRAPE = "scrape";
const GIFTSTEPCHANGE = "change";

export const GiftReqModal = ({ show, onHide, order }) => {
  const { user, fetchGift } = useUser();
  const initGiftInfo = {
    sender: {
      displayName: user?.name,
    },
    orderIDs: [order?._id],
    pw: "",
    isHidePrice: false,
  };
  const [giftInfo, setGiftInfo] = useState(initGiftInfo);
  const { addToast } = useToast();

  useEffect(() => {
    if (!order) return;

    fetchGift(order._id).then((res) => {
      // if (res&&res.length > 0) setGiftInfo(res[0]);
      res && res.length > 0 ? setGiftInfo(res[0]) : setGiftInfo(initGiftInfo);
    });
  }, [order]);

  const handleSubmit = async () => {
    try {
      if (giftInfo.sender.displayName.trim() === "")
        throw new Error("請輸入顯示名稱");

      const url = await requestGiftUrl(giftInfo);

      let data = {
        // title: `NearMe-來自${user.name}的禮物，10天內需收下或更改禮物，否則交易自動取消`,
        text: `NearMe-來自${giftInfo.sender.displayName}的禮物，10天內需收下或更改禮物，否則交易自動取消`,
        url,
      };

      let result = await copyShare(data);

      if (!result) throw new Error("複製失敗");

      // addToast("成功複製連結", { appearance: "success" });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  if (!order) return <EmptyPage title={"尚未選取禮物"} />;

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={() => setGiftInfo(initGiftInfo)}
      className="bottom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>發送禮物</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Row className="text-muted justify-content-between align-items-center">
              <Col>
                <strong className="text-primary">
                  {order.products[0].productName}
                </strong>
              </Col>
              <Col xs="auto">
                <strong>
                  <FontAwesomeIcon icon={faTimes} /> {order.products[0].amount}
                </strong>
              </Col>
            </Row>
          </Col>
          <Col xs="auto">
            <Image
              src={order.products[0].images[0]}
              width="80px"
              height="80px"
              style={{ borderRadius: "20px" }}
            />
          </Col>
        </Row>
        <br />
        <Form>
          <Form.Group className="mb-3">
            <Row className="justify-content-between">
              <Col xs="auto">
                <Form.Label>顯示名稱</Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Control
                  type="text"
                  placeholder="輸入顯示名稱"
                  value={giftInfo.sender.displayName || ""}
                  onChange={(e) =>
                    setGiftInfo({
                      ...giftInfo,
                      sender: {
                        ...giftInfo.sender,
                        displayName: e.target.value,
                      },
                    })
                  }
                />
              </Col>
            </Row>
          </Form.Group>

          {/* <Form.Group className="mb-3">
            <Form.Label>密碼</Form.Label>
            <Form.Control
              type="password"
              placeholder="輸入密碼"
              value={giftInfo.pw}
              onChange={(e) => setGiftInfo({ ...giftInfo, pw: e.target.value })}
            />
          </Form.Group> */}

          <Form.Group className="mb-3">
            <Row className="justify-content-between">
              <Col>
                <Form.Label>隱藏價格</Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Switch
                  type="checkbox"
                  checked={giftInfo.isHidePrice}
                  onChange={(e) =>
                    setGiftInfo({ ...giftInfo, isHidePrice: e.target.checked })
                  }
                />
              </Col>
            </Row>
          </Form.Group>
          <Stack className="mx-auto col-6">
            <Button variant="primary" onClick={handleSubmit}>
              發送禮物
            </Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const GiftReqPage = () => {
  const [pickShow, setPickShow] = useState(false);
  const [giftInfo, setGiftInfo] = useState({
    pickOrder: [],
    pw: "",
    keepOther: false,
  });
  const [needPw, setNeedPw] = useState(false);
  const { ownOrder, fetchOrder, user } = useUser();
  const avaliableOrder = useMemo(() => {
    // if (!ownOrder) return [];
    return ownOrder.filter((e) => e.status === "get");
  }, [ownOrder]);
  const location = useLocation();
  const { addToast } = useToast();
  const swiperRef = useRef(null);

  useEffect(() => {
    let q = new URLSearchParams(location.search);
    let gid = q.get("addGift");
    let gift = ownOrder.find((e) => e._id === gid);
    if (gift) handlePick(gift);
  }, [location, ownOrder]);

  useEffect(() => {
    fetchOrder();
  }, []);

  const scrollToLatestSlide = () => {
    if (swiperRef.current) {
      const latestSlideIndex = swiperRef.current.swiper.slides.length - 1;
      console.log(latestSlideIndex);
      swiperRef.current.swiper.slideTo(latestSlideIndex);
    }
  };

  const handlePick = (order) => {
    let newPick = [...giftInfo.pickOrder];
    let exist = newPick.find((e) => e._id === order._id);
    if (exist) return;
    // newPick.push(order);
    newPick[0] = order;
    setGiftInfo({ ...giftInfo, pickOrder: newPick });
  };

  const handleDel = (orderId) => {
    let newPick = [...giftInfo.pickOrder];
    newPick = newPick.filter((e) => e._id !== orderId);
    setGiftInfo({ ...giftInfo, pickOrder: newPick });
  };

  const GiftCard = ({ order }) => {
    return (
      <Card className="gift-card">
        <Row className="justify-content-center">
          <Col xs="auto">
            <div style={{ position: "absolute", top: "-8rem", right: "0" }}>
              <CloseButton onClick={() => handleDel(order._id)} />
            </div>
            <Card.Img
              src={order.products[0].images[0]}
              className="gift-card-img"
            />
          </Col>
        </Row>
        <Card.Body className="gift-card-content">
          <strong>{order.products[0].productName}</strong>

          <OverflowText text={order.products[0].description} max="250" />
        </Card.Body>
      </Card>
    );
  };

  const handleSubmit = async () => {
    try {
      if (needPw && !giftInfo.pw.trim()) throw new Error("密碼不可空白");

      let giftData = { ...giftInfo };

      giftData.tradeNos = giftInfo.pickOrder.map((e) => e.tradeNo);

      delete giftData.pickOrder;

      let url = await requestGiftUrl(giftData);

      let data = {
        // title: `NearMe-來自${user.name}的禮物，10天內需收下或更改禮物，否則交易自動取消`,
        text: `NearMe-來自${user.name}的禮物，10天內需收下或更改禮物，否則交易自動取消`,
        url,
      };

      let result = await copyShare(data);

      if (!result) throw new Error("複製失敗");

      // addToast("成功複製連結", { appearance: "success" });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const PickGiftModal = ({ show, onHide, orderList = [] }) => {
    return (
      <Modal show={show} onHide={onHide} onExited={() => scrollToLatestSlide()}>
        <Modal.Header closeButton>挑選禮物</Modal.Header>
        <Modal.Body>
          {orderList.length === 0 && (
            <div>
              <h4 className="text-muted text-center">
                還沒有購買商品可以送禮喔
              </h4>
              <br />
              <Row className="justify-content-center">
                <Col xs="auto">
                  <Button variant="outline-primary">去購買</Button>
                </Col>
              </Row>
            </div>
          )}
          <ListGroup style={{ maxHeight: "500px", overflow: "auto" }}>
            {orderList.map((order) => (
              <ListGroup.Item
                key={order._id}
                onClick={(e) => {
                  handlePick(order);
                  onHide();
                  scrollToLatestSlide();
                }}
              >
                <Row className="justify-content-between">
                  <Col xs="auto">
                    <img
                      src={order.products[0].images[0]}
                      width="100px"
                      height="100px"
                      style={{ borderRadius: "18px" }}
                    />
                  </Col>
                  <Col>
                    <strong className="text-primary">
                      {order.sellerData.name}
                    </strong>
                    <br />
                    <strong>{order.products[0].productName}</strong>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
    );
  };

  if (avaliableOrder?.length < 1) {
    return (
      <div>
        <EmptyPage title="目前沒有可以送的禮物">
          <Link to="/">
            <Button variant="outline-primary">挑選禮物</Button>
          </Link>
        </EmptyPage>
      </div>
    );
  }

  return (
    <div>
      {/* <PickGiftModal
        show={pickShow}
        onHide={() => setPickShow(false)}
        orderList={ownOrder
          .filter((e) => e.status === "get")
          .filter((e) => !giftInfo.pickOrder.map((e) => e._id).includes(e._id))}
      /> */}
      <Row xs={1} className="justify-content-center">
        <Col xs={12} md={8}>
          <h3>挑選禮物</h3>
          <hr />
          {avaliableOrder.map((e) => (
            <div
              key={e._id}
              className={`my-2 ${
                giftInfo.pickOrder.map((e) => e._id).includes(e._id) &&
                "shadow pick-order"
              }`}
            >
              <OrderShortCard order={e} cb={(order) => handlePick(order)} />
            </div>
          ))}
        </Col>
        <Col xs={12} md={8}>
          <h3 className="">禮物明細</h3>
          <hr />
          <Card className="my-2">
            <Card.Body>
              {/* <Form.Group
                as={Row}
                className="my-2 justify-content-between align-items-center"
                controlId="keepOther"
              >
                <Form.Label column>
                  <h5 className="text-primary">
                    <strong>保留禮物</strong>
                  </h5>
                  <Form.Text id="keepOtherHelpBlock" muted>
                    當收禮者選擇其中一個禮物時，其自動退款其他禮物
                  </Form.Text>
                </Form.Label>
                <Col xs="auto">
                  <Form.Switch
                    className="ms-auto"
                    id="gift-keepOther-switch"
                    checked={giftInfo.keepOther}
                    onChange={(e) =>
                      setGiftInfo((pre) => {
                        return { ...pre, keepOther: e.target.checked };
                      })
                    }
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group
                as={Row}
                className="my-2 justify-content-between align-items-center"
                controlId="pw"
              >
                <Form.Label column>
                  <h5 className="text-primary">
                    <strong>設定暗號</strong>
                  </h5>
                  <Form.Text id="keepOtherHelpBlock" muted>
                    收禮者需要輸入暗號才能接收禮物
                  </Form.Text>
                </Form.Label>

                <Col>
                  {needPw && (
                    <Form.Control
                      value={giftInfo.pw}
                      onChange={(e) =>
                        setGiftInfo((pre) => {
                          return { ...pre, pw: e.target.value };
                        })
                      }
                    />
                  )}
                </Col>
                <Col xs="auto">
                  <Form.Switch
                    className="ms-auto"
                    id="gift-pw-switch"
                    checked={needPw}
                    onChange={(e) => setNeedPw(e.target.checked)}
                  />
                </Col>
              </Form.Group> */}
              <Row className="justify-content-center">
                <Col xs="auto">
                  <Button onClick={handleSubmit} variant="outline-primary">
                    發送禮物 <FontAwesomeIcon icon={faGift} />
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

// Must be place outside of modal to avoid over render issue !!!
export const MarkUpForm = ({ paymentData, setPaymentData }) => {
  return (
    <section>
      <Row xs={1} md={2}>
        <Col className="mb-2">
          <h5>電子發票</h5>
          <hr />

          <InvoiceForm
            cb={(invoiceSetting) => {
              setPaymentData({
                ...paymentData,
                invoiceSetting,
              });
            }}
          />
        </Col>
        <Col className="mb-2">
          <h5>付款方式</h5>
          <hr />

          <PaymentProvider
            data={paymentData?.provider}
            cb={(provider) => setPaymentData({ ...paymentData, provider })}
          />
        </Col>
      </Row>
    </section>
  );
};

// Must be place outside of modal to avoid over render issue !!!
export const MarkDownForm = ({
  updateData,
  setUpdateData,
  handleChangeAmount,
  pickOrder,
}) => {
  const { productId, amount, specIndex } = updateData.products[0];
  return (
    <section>
      <OrderCardWithOption
        order={updateData}
        productData={updateData.products[0].productId}
        amountCb={handleChangeAmount}
      />
      <PayAmountDetail
        productId={productId._id}
        amount={amount}
        specIndex={specIndex}
        isFreeServiceFee={true}
        baseOn={pickOrder}
      />
      <br />

      <GiftPickForm
        cb={(data) =>
          setUpdateData({
            ...updateData,
            gift: { ...updateData.gift, picker: data },
          })
        }
      />
    </section>
  );
};

const CheckGiftModal = ({
  show,
  onHide,
  pickOrder,
  setPickGift,
  handleCheck,
}) => {
  const [pending, setPending] = useState(false);
  const { addToast } = useToast();

  const handleSubmit = async () => {
    try {
      setPending(true);

      await handleCheck(pickOrder, "");
      onHide();
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    } finally {
      setPending(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="xl" scrollable>
      <Modal.Header closeButton />
      <Modal.Body>
        <h4>填寫收禮資料</h4>
        <hr />
        <GiftPickForm
          data={pickOrder?.gift?.picker}
          cb={(data) =>
            setPickGift((pre) => {
              return { ...pre, gift: { ...pre.gift, picker: data } };
            })
          }
        />
        <br />
        <Row className="justify-content-center">
          <Col xs="auto">
            <Button onClick={handleSubmit} disabled={pending}>
              {pending ? <Spinner animation="border" /> : "確認"}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const GiftCard = ({ giftData, handleCheck, handleChange, isHidePrice }) => {
  const [gift, setGift] = useState(giftData);
  const [productContent, setProductContent] = useState(null);
  const product = gift.products[0];
  const btnTitle = useMemo(() => {
    if (gift.status === process.env["REACT_APP_COUPON_STATUS_REFUND"])
      return "已失效";
    if (gift.status === process.env["REACT_APP_COUPON_STATUS_CHECK"])
      return "已收禮";
    if (gift.status === process.env["REACT_APP_COUPON_STATUS_EXPIRED"])
      return "已過期";
    if (gift.status === process.env["REACT_APP_COUPON_STATUS_CATCH"])
      return "接收禮物";
    return "錯誤";
  }, [gift]);

  useEffect(() => {
    fetchProduct(product.productId).then((data) => {
      setProductContent(data);
    });
  }, [giftData]);

  const specValue = useMemo(() => {
    const specGroup = product.productId.specGroup;

    if (!specGroup) return null;

    const pickSpec = specGroup[product.specIndex];

    return Object.values(pickSpec.specs).join(" | ");
  }, [gift]);

  const isAvaSwitchSpec = useMemo(() => {
    const specGroup = product.productId.specGroup;
    if (!specGroup) return false;
    const difPrice = new Set(specGroup.map((e) => e.price));
    if (difPrice.size > 1) return false;
    return true;
  }, [gift]);

  const credit = useMemo(() => {
    return product.itemPrice * product.amount;
  }, [gift]);

  return (
    <>
      <Card className="gift-card">
        <Row className="justify-content-center">
          <Col xs="auto">
            <Card.Img className="gift-card-img" src={product.images[0]} />
          </Col>
        </Row>
        <Card.Body className="gift-card-content">
          {/* <strong>
          到期日: {new Date(product.expiredDate).toLocaleDateString()}
        </strong>
        <br /> */}
          <Stack gap={2}>
            <h4 style={{ color: "#10567B" }}>
              <strong>{isHidePrice ? "" : `$${credit}`}</strong>
            </h4>
            <strong className="text-primary">{gift.sellerData.name}</strong>

            <strong>{product.productName}</strong>
            {isAvaSwitchSpec ? (
              // <Form.Select
              //   value={product.specIndex}
              //   // onChange={(e) => handleChangeSpec(e.target.value)}
              //   onChange={(e) => {
              //     let newGift = { ...gift };
              //     newGift.products[0].specIndex = e.target.value;
              //     setGift(newGift);
              //   }}
              // >
              //   {product.productId.specGroup.map((o, idx) => (
              //     <option key={idx} value={idx}>
              //       {Object.values(o.specs).sort().join(" | ")}
              //     </option>
              //   ))}
              // </Form.Select>
              <SpecSelector
                specGroup={product.productId.specGroup}
                pickSpec={product.specIndex}
                setPickSpec={(specIndex) => {
                  let newGift = { ...gift };
                  newGift.products[0].specIndex = specIndex;
                  setGift(newGift);
                }}
              />
            ) : (
              <p>{specValue}</p>
            )}
          </Stack>
          <br />
          <OverflowText text={product.description} max="20" />
        </Card.Body>
        {/* <Card.Footer
          className="d-flex justify-content-center bg-white border-0 text-center text-primary"
          style={{ borderRadius: "0 0 18px 18px" }}
        >
          {gift.status === process.env["REACT_APP_COUPON_STATUS_CATCH"] ? (
            <>
              <Col onClick={handleCheck} className="">{btnTitle}</Col>
              <Col xs="auto">
                <div className="vr" />
              </Col>
              <Col onClick={handleChange}>更改禮物</Col>
            </>
          ) : (
            <h5 className="text-muted">{btnTitle}</h5>
          )}
        </Card.Footer> */}
        <Card.Footer
          className="p-0 d-flex justify-content-between align-items-stretch border-0"
          style={{ borderRadius: "0 0 18px 18px", overflow: "hidden" }}
        >
          {gift.status === process.env["REACT_APP_COUPON_STATUS_CATCH"] ? (
            <>
              <Button
                variant="primary"
                className="flex-grow-1 border-0 rounded-0 py-2"
                style={{ borderBottomLeftRadius: "18px" }}
                onClick={handleCheck}
                disabled={
                  gift.status !== process.env["REACT_APP_COUPON_STATUS_CATCH"]
                }
              >
                {btnTitle}
              </Button>
              <Button
                className="flex-grow-1 border-0 rounded-0 py-2 bg-white text-primary"
                style={{ borderBottomRightRadius: "18px" }}
                onClick={handleChange}
              >
                更改禮物
              </Button>
            </>
          ) : (
            <Stack className="text-center bg-white">

            <h5 className="text-muted">{btnTitle}</h5>
            </Stack>
          )}
        </Card.Footer>
      </Card>
      <Tabs fill defaultActiveKey={"p"} className="sticky-top bg-white py-2">
        <Tab eventKey={"p"} title="商品資訊" className="py-3">
          {productContent?.detailHtml && (
            <ProductDetailSection detailHtml={productContent?.detailHtml} />
          )}
        </Tab>
        <Tab eventKey={"o"} title="訂單須知" className="py-3">
          <RefundNotice />

          <br />
          <ReceiverFAQ />
        </Tab>
        <Tab eventKey={"r"} title="評論" className="py-3">
          {productContent && (
            <PlatformReviewList
              platform={productContent?.platform}
              platformPId={productContent?.productId}
            />
          )}
        </Tab>
      </Tabs>
    </>
  );
};

const CheckStep = (
  {
    // pickerData, setPickerData, handleCheck
  }
) => {
  const [pending, setPending] = useState(false);
  const { pickerData, setPickerData, handleCheck } = useGift();

  const handleSubmit = async () => {
    setPending(true);
    await handleCheck(pickerData);
    setPending(false);
  };
  return (
    <div>
      <GiftPickForm data={pickerData} cb={(data) => setPickerData(data)} />
      <br />
      <Row className="justify-content-center">
        <Col xs="auto">
          <Button onClick={handleSubmit} disabled={pending}>
            {pending ? <Spinner animation="border" /> : "確認"}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const SearchGiftStep = (
  {
    // giftSpreadDiscount
  }
) => {
  const location = useLocation();
  const { giftSpreadDiscount } = useGift();
  const { updateQueryParams } = useUrlQuery();
  const urlP = new URLSearchParams(location.search);
  const step = urlP.get("step");
  let searchText = useMemo(
    () => (step === GIFTSTEPSEARCH ? urlP.get("q") : ""),
    [location.search]
  );

  const recommendWords = [
    { title: "中秋特輯", keyword: "中秋" },
    { title: "父親節特輯", keyword: "父親節" },
    { title: "耳飾", keyword: "耳飾" },
    { title: "項鍊", keyword: "項鍊" },
    { title: "巧克力", keyword: "巧克力" },
    { title: "手錶", keyword: "手錶" },
    { title: "行李箱", keyword: "行李箱" },
    { title: "保健食品", keyword: "人參液" },
  ];

  const HomeRecommend = ({ title, qWord }) => {
    const elementRef = useRef(null);
    // const { entry, ob } = useIntersectionObserver(elementRef, {
    //   rootMargin: "0px 0px 720px 0px",
    //   freezeOnceVisible: false,
    // });

    const [ref, isIntersecting] = useIO({
      rootMargin: "0px 0px 300px 0px",
      threshold: 0.1,
    });

    // useEffect(() => {
    //   if (entry && entry.isIntersecting) ob.disconnect();
    // }, [entry]);

    return (
      <section ref={ref} style={{ minHeight: "360px" }}>
        <h5>
          <Link
            className="text-decoration-none text-dark"
            to={`/search?q=${qWord}`}
          >
            {title}
          </Link>
          <FontAwesomeIcon icon={faGift} className="text-primary" />
        </h5>
        {isIntersecting && (
          <SearchProductList
            giftSpreadDiscount={giftSpreadDiscount}
            qText={qWord}
            horizontal
            morePage={false}
            pFilter={false}
            productCB={(product) =>
              // history.push(`/search?q=${product.productLink}`)
              updateQueryParams({
                step: GIFTSTEPSCRAPE,
                scrapeUrl: encodeURIComponent(product.productLink),
              })
            }
          />
        )}
      </section>
    );
  };

  return (
    <div>
      <strong>搜尋你想更改的禮物吧</strong>
      <hr />
      <GiftSearchBar cb={(text) => updateQueryParams({ q: text })} />
      <br />
      {!searchText?.trim() ? (
        <section className="recommand-products-section">
          {recommendWords.map((e, idx) => (
            <section key={e.title}>
              <HomeRecommend title={e.title} qWord={e.keyword} />
              {/* {idx === 4 && (
                <Stack className="my-5 col-md-8 mx-auto">
                  <SearchCard />
                </Stack>
              )}

              {idx === 1 && (
                <Stack className="my-5 col-md-8 mx-auto">
                  <GiftNoteBanner />
                </Stack>
              )} */}
            </section>
          ))}
        </section>
      ) : (
        <SearchProductList
          giftSpreadDiscount={giftSpreadDiscount}
          qText={searchText || ""}
          morePage={true}
          productCB={(product) =>
            updateQueryParams({
              step: GIFTSTEPSCRAPE,
              scrapeUrl: product.productLink,
            })
          }
        />
      )}
    </div>
  );
};

const SearchChangeProductStep = () => {
  const [typeText, setTypeText] = useState("");
  const [products, setProducts] = useState({});
  const [pending, setPending] = useState(false);
  const [morePending, setMorePending] = useState(false);
  const [productFiler, setProductFiler] = useState({
    platform: ["momo", "pinkoi", "linegift"],
  });
  const [sortType, setSortType] = useState("auto");
  const [page, setPage] = useState(1);
  const [searchQueue, setSearchQueue] = useState([]);
  const errMsg = useErrorMsg();
  const location = useLocation();
  const { updateQueryParams } = useUrlQuery();
  const step = new URLSearchParams(location.search).get("step");
  const searchText = new URLSearchParams(location.search).get("q");

  const momoData = [
    {
      platform: "momo",
      productName: "【COACH】MICHAEL KORS 斜背包/肩背包/兩用包(多款多色任選)",
      productId: "",
      storeName: "",
      price: 3799,
      imgs: [
        "https://i4.momoshop.com.tw/1711083805/goodsimg/0010/839/402/10839402_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=10839402&mdiv=searchEngine&oid=1_1&kw=包包",
    },
    {
      platform: "momo",
      productName:
        "【COACH】MICHAEL KORS 斜背包/肩背包/兩用包/禮盒組(多款多色任選/交換禮物)",
      productId: "",
      storeName: "",
      price: 2999,
      imgs: [
        "https://i3.momoshop.com.tw/1712120547/goodsimg/0010/630/111/10630111_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=10630111&mdiv=searchEngine&oid=1_2&kw=包包",
    },
    {
      platform: "momo",
      productName:
        "【ELLE & 英國小獅 & KANGOL】買一送一。買包送正貨小獅包│經典簡約帆布托特包/水餃包/肩背包(多款任選)",
      productId: "",
      storeName: "",
      price: 1199,
      imgs: [
        "https://i2.momoshop.com.tw/1712034381/goodsimg/0010/821/578/10821578_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=10821578&mdiv=searchEngine&oid=1_3&kw=包包",
    },
    {
      platform: "momo",
      productName: "【NINE WEST官方直營】肩背包/長短夾均一價(限量絕版品)",
      productId: "",
      storeName: "",
      price: 990,
      imgs: [
        "https://i1.momoshop.com.tw/1712572332/goodsimg/0008/453/035/8453035_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=8453035&mdiv=searchEngine&oid=1_4&kw=包包",
    },
    {
      platform: "momo",
      productName:
        "【PLAYBOY】馬鞍包/斜背包/手提包/腰包/後背包/長夾/托特包(多款任選)",
      productId: "",
      storeName: "",
      price: 1350,
      imgs: [
        "https://i3.momoshop.com.tw/1712207024/goodsimg/0011/544/783/11544783_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=11544783&mdiv=searchEngine&oid=1_5&kw=包包",
    },
    {
      platform: "momo",
      productName: "【CHENSON】輕量3夾層4口袋 托特包 斜背包(CG83811)",
      productId: "",
      storeName: "",
      price: 1488,
      imgs: [
        "https://i4.momoshop.com.tw/1712291105/goodsimg/0011/096/332/11096332_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=11096332&mdiv=searchEngine&oid=1_6&kw=包包",
    },
    {
      platform: "momo",
      productName:
        "【RH】耐用大容量多層外出斜背包(乙多色任選百搭多層收納好搭配)",
      productId: "",
      storeName: "",
      price: 599,
      imgs: [
        "https://i4.momoshop.com.tw/1693065333/goodsimg/0008/798/059/8798059_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=8798059&mdiv=searchEngine&oid=1_7&kw=包包",
    },
    {
      platform: "momo",
      productName:
        "【LONSDALE 英國小獅】經典簡約旅行多用途包款 / 肩背包 / 胸包 / 腰包(多款任選)",
      productId: "",
      storeName: "",
      price: 1280,
      imgs: [
        "https://i4.momoshop.com.tw/1712207190/goodsimg/0012/661/889/12661889_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=12661889&mdiv=searchEngine&oid=1_8&kw=包包",
    },
    {
      platform: "momo",
      productName:
        "【LONGCHAMP】Michael Kors&COACH水餃包/漁網包/斜背包/圍巾(多款選)",
      productId: "",
      storeName: "",
      price: 3533,
      imgs: [
        "https://i2.momoshop.com.tw/1712546399/goodsimg/0011/931/330/11931330_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=11931330&mdiv=searchEngine&oid=1_9&kw=包包",
    },
    {
      platform: "momo",
      productName:
        "【L’ANDYA☆台灣好購】真皮女用寬肩帶斜背包(頭層牛皮/手拿包/側背包/單肩包/軟皮休閒包/寬版撞色肩帶)",
      productId: "",
      storeName: "",
      price: 768,
      imgs: [
        "https://i4.momoshop.com.tw/1693548420/goodsimg/0011/648/128/11648128_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=11648128&mdiv=searchEngine&oid=1_10&kw=包包",
    },
    {
      platform: "momo",
      productName: "【COACH】斜背包/肩背包/兩用包(多款多色任選)",
      productId: "",
      storeName: "",
      price: 6899,
      imgs: [
        "https://i2.momoshop.com.tw/1712572158/goodsimg/0011/621/813/11621813_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=11621813&mdiv=searchEngine&oid=1_11&kw=包包",
    },
    {
      platform: "momo",
      productName:
        "【吉豐洋行】時尚圖騰揹帶多隔層防潑水斜背包/側背包(多色可選)",
      productId: "",
      storeName: "",
      price: 499,
      imgs: [
        "https://i1.momoshop.com.tw/1704364754/goodsimg/0010/103/522/10103522_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=10103522&mdiv=searchEngine&oid=1_12&kw=包包",
    },
    {
      platform: "momo",
      productName:
        "【Bliss BKK】復古幾何拼接皮革手提斜跨包 美拉德 手提包 斜背包(4色可選)",
      productId: "",
      storeName: "",
      price: 359,
      imgs: [
        "https://i2.momoshop.com.tw/1703667875/goodsimg/0012/264/871/12264871_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=12264871&mdiv=searchEngine&oid=1_13&kw=包包",
    },
    {
      platform: "momo",
      productName:
        "【TRAILOS 翠樂絲】韓版觸控時尚手機包(手機側背包/斜背包/側背包)",
      productId: "",
      storeName: "",
      price: 204,
      imgs: [
        "https://i3.momoshop.com.tw/1692353095/goodsimg/0011/747/751/11747751_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=11747751&mdiv=searchEngine&oid=1_14&kw=包包",
    },
    {
      platform: "momo",
      productName: "【COACH】斜背包/肩背包/兩用包(多款多色任選)",
      productId: "",
      storeName: "",
      price: 4888,
      imgs: [
        "https://i1.momoshop.com.tw/1712120482/goodsimg/0010/929/390/10929390_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=10929390&mdiv=searchEngine&oid=1_15&kw=包包",
    },
    {
      platform: "momo",
      productName: "【COACH】DEMPSEY 緹花/皮革抽繩水桶斜背包(多色選)",
      productId: "",
      storeName: "",
      price: 5680,
      imgs: [
        "https://i2.momoshop.com.tw/1706585497/goodsimg/0012/264/581/12264581_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=12264581&mdiv=searchEngine&oid=1_16&kw=包包",
    },
    {
      platform: "momo",
      productName: "【EZlife】時尚炫彩大容量多格側背包(贈袖套)",
      productId: "",
      storeName: "",
      price: 439,
      imgs: [
        "https://i2.momoshop.com.tw/1692268401/goodsimg/0010/221/113/10221113_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=10221113&mdiv=searchEngine&oid=1_17&kw=包包",
    },
    {
      platform: "momo",
      productName: "【MUJI 無印良品】撥水加工尼龍附筆套肩背包(黑色)",
      productId: "",
      storeName: "",
      price: 750,
      imgs: [
        "https://i3.momoshop.com.tw/1702976481/goodsimg/0012/231/174/12231174_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=12231174&mdiv=searchEngine&oid=1_18&kw=包包",
    },
    {
      platform: "momo",
      productName:
        "【吉豐洋行】休閒時尚多隔層防潑水斜背包/側背包(搭配圖騰背帶)",
      productId: "",
      storeName: "",
      price: 498,
      imgs: [
        "https://i1.momoshop.com.tw/1694685727/goodsimg/0009/391/905/9391905_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=9391905&mdiv=searchEngine&oid=1_19&kw=包包",
    },
    {
      platform: "momo",
      productName: "【Michael Kors】JET SET荔枝皮壓釦寬背袋WOC斜背包(多色選)",
      productId: "",
      storeName: "",
      price: 5080,
      imgs: [
        "https://i4.momoshop.com.tw/1699002373/goodsimg/0012/057/267/12057267_OL_m.webp",
      ],
      productLink:
        "https://m.momoshop.com.tw/goods.momo?i_code=12057267&mdiv=searchEngine&oid=1_20&kw=包包",
    },
  ];

  const handleProductsSort = useCallback((newList) => {
    let existP = new Set(newList.map((e) => e.platform));

    let sortList = [];

    for (let i in newList) {
      existP.forEach((e) => {
        let fIndex = newList.findIndex((p) => p?.platform === e);

        if (fIndex < 0) return;

        sortList.push(newList[fIndex]);

        newList[fIndex] = null;
      });
    }
    return sortList;
  }, []);

  const handleProductsDeduplicate = useCallback((products) => {
    products = products.filter(
      (obj, index, self) =>
        index === self.findIndex((t) => t.productLink === obj.productLink)
    );
    return products;
  }, []);

  const filtedProduct = useMemo(() => {
    let newList = [];

    let pageValue = Object.values(products);

    pageValue.forEach((pageData) => {
      let platformValue = Object.values(pageData);
      let pageSort = [];
      platformValue.forEach((platformData) => {
        pageSort = [...pageSort, ...platformData];
      });
      pageSort = handleProductsSort(pageSort);
      pageSort = handleProductsDeduplicate(pageSort);

      if (sortType === "priceDesc") {
        pageSort = pageSort.sort((a, b) => b.price - a.price);
      }

      if (sortType === "priceAsc") {
        pageSort = pageSort.sort((a, b) => a.price - b.price);
      }

      newList = [...newList, ...pageSort];
    });

    newList = newList.filter((e) => {
      return productFiler.platform.includes(e.platform);
    });

    return newList;
  }, [productFiler, products, step, sortType]);

  useImgaeLoadObserver({
    objectSelector: `[data-src^="https"]`,
    dep: [filtedProduct],
  });

  const searchPlatform = ["momo", "pinkoi", "linegift"];

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText]);

  // More fetch
  const sentinelRef = useRef(null);

  useEffect(() => {
    // 定義 IntersectionObserver
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !morePending) {
          // 如果 Sentinel 元素進入視窗，加載更多商品
          setPage((prevPage) => prevPage + 1);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1, // 監測視窗與 Sentinel 元素相交程度
      }
    );

    // 將 Sentinel 元素綁定到 Observer
    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    // 清除 Observer
    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [sentinelRef, products]);

  useEffect(() => {
    if (page <= 1) return;

    setMorePending(true);

    const queryArray = searchPlatform.map((e) => {
      return searchProduct(searchText, e, page)
        .then((data) => {
          setProducts((pre) => {
            return {
              ...pre,
              [`page${page}`]: {
                ...pre[`page${page}`],
                [e]: data,
              },
            };
          });
        })
        .catch((err) => console.log(err.message))
        .finally(() => {
          searchQueue.current = searchQueue.current.filter(
            (qTitle) => qTitle != e
          );
        });
    });

    Promise.all(queryArray).finally(() => setMorePending(false));
  }, [page]);

  const handleSearch = async (searchText, page = 1) => {
    try {
      if (pending) return;

      if (!searchText) throw new Error("請輸入搜尋內容");

      errMsg.setMsg("");

      setPending(true);

      if (
        /[a-zA-Z0-9]{24}/.test(searchText) ||
        /https?:\/\/\S+/.test(searchText)
      ) {
        updateQueryParams({ step: GIFTSTEPSCRAPE, scrapeUrl: searchText });
        return;
      }

      setSearchQueue(searchPlatform);

      setProducts({});

      searchPlatform.forEach((e) => {
        searchProduct(searchText, e, page)
          .then((data) => {
            setProducts((pre) => {
              return {
                ...pre,
                [`page${page}`]: {
                  ...pre[`page${page}`],
                  [e]: data,
                },
              };
            });
          })
          .finally(() => {
            setSearchQueue((pre) => pre.filter((qTitle) => qTitle != e));
          });
        // setProducts(momoData);
      });
    } catch (err) {
      errMsg.setMsg(err.message);
    } finally {
      setPending(false);
    }
  };

  return (
    <Stack className="col-md-8 mx-auto">
      <ProductSearchBar cb={(text) => updateQueryParams({ q: text })} />
      <br />
      {/* Filter & sorter */}
      {Object.keys(products).length > 0 && (
        <Row className="justify-content-end my-2">
          <Col xs="auto">
            <DropdownButton
              variant="outline-primary"
              title={
                <Stack direction="horizontal" gap={1}>
                  篩選
                  <FontAwesomeIcon icon={faFilter} />
                </Stack>
              }
              size="sm"
            >
              {[
                { title: "Line Gift", value: "linegift" },
                { title: "Momo", value: "momo" },
                { title: "Pinkoi", value: "pinkoi" },
              ].map((e) => (
                <Dropdown.Item
                  key={e.value}
                  onClick={() => {
                    productFiler.platform.includes(e.value)
                      ? setProductFiler({
                          platform: productFiler.platform.filter(
                            (p) => p != e.value
                          ),
                        })
                      : setProductFiler({
                          platform: productFiler.platform.concat(e.value),
                        });
                  }}
                >
                  <Stack direction="horizontal">
                    <strong>{e.title}</strong>
                    {productFiler.platform.includes(e.value) && (
                      <FontAwesomeIcon
                        className="ms-auto text-primary"
                        icon={faCheck}
                      />
                    )}
                  </Stack>
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Col>
          <Col xs="auto">
            <DropdownButton
              variant="outline-primary"
              title={
                <Stack direction="horizontal" gap={1}>
                  排序
                  <FontAwesomeIcon icon={faSort} />
                </Stack>
              }
              size="sm"
            >
              {[
                { title: "自動", value: "auto" },
                { title: "價錢高至低", value: "priceDesc" },
                { title: "價錢低至高", value: "priceAsc" },
              ].map((e) => (
                <Dropdown.Item
                  key={e.value}
                  onClick={() => setSortType(e.value)}
                >
                  <Stack direction="horizontal">
                    <strong>{e.title}</strong>
                    {sortType === e.value && (
                      <FontAwesomeIcon
                        className="ms-auto text-primary"
                        icon={faCheck}
                      />
                    )}
                  </Stack>
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Col>
        </Row>
      )}
      <br />
      {(pending || searchQueue.length > 0) && (
        <Stack>
          <ProgressBar
            style={{ height: "5px" }}
            now={
              ((searchPlatform.length - searchQueue.length) /
                searchPlatform.length) *
              100
            }
            className="my-1"
          />
          <Spinner className="mx-auto" animation="grow" />
        </Stack>
      )}
      {!pending &&
        !errMsg.msg &&
        Object.keys(products?.page1 || {}).length === searchPlatform.length &&
        filtedProduct.length === 0 && (
          <EmptyPage title={"找不到商品，試試其他關鍵字吧"} h="60vh" />
        )}
      {filtedProduct.length > 0 && (
        <Stack>
          <Row xs={2} md={3}>
            {filtedProduct.map((e) => (
              <Col key={e.productLink} className="my-1">
                <ProductSearchItem
                  data={e}
                  key={e.productLink}
                  cb={(data) => handleSearch(data.productLink)}
                />
              </Col>
            ))}
          </Row>
          <Stack ref={sentinelRef} />
          {page > 1 && morePending && (
            <Stack>
              <Spinner className="mx-auto" />
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

const ScrapeProductStep = (
  {
    // setNewOrder, giftSpreadDiscount
  }
) => {
  const { setNewOrder, giftSpreadDiscount } = useGift();
  const [targetProduct, setTargetProduct] = useState(null);
  const [pending, setPending] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const { updateQueryParams } = useUrlQuery();
  const location = useLocation();
  const scrapeUrl = new URLSearchParams(location.search).get("scrapeUrl");

  useEffect(() => {
    if (!scrapeUrl) return;
    setTargetProduct(null);
    setPending(true);
    scrapeProduct(scrapeUrl)
      .then((e) => setTargetProduct(e))
      .catch((err) => setErrMsg(err.message))
      .finally(() => setPending(false));
  }, [scrapeUrl]);

  const handleOnClick = (pickInfo) => {
    console.log("pickInfo", pickInfo);
    const { productData, pickSpec, amount } = pickInfo;
    let queryP = { step: "change", amount, scrapeUrl: productData._id };
    if (targetProduct.specGroup && pickSpec >= 0)
      queryP["specIndex"] = pickSpec;

    setNewOrder((pre) => {
      return {
        ...pre,
        sellerData: productData.sellerData,
        products: [
          {
            productId: productData,
            productName: productData.productName,
            itemPrice: productData.price.special || productData.price.original,
            amount: amount || 1,
            images: productData.images,
            specIndex: pickSpec,
          },
        ],
        fee: {
          shipping: productData.fee.shipping,
        },
      };
    });
    updateQueryParams(queryP);
  };
  if (pending) {
    return (
      <Stack>
        <Spinner className="mx-auto" />
      </Stack>
    );
  }
  if (!targetProduct) {
    return <EmptyPage title={errMsg} />;
  }

  return (
    <ScrapeProductSearchResult
      data={targetProduct}
      handlePay={handleOnClick}
      giftSpreadDiscount={giftSpreadDiscount}
    />
  );
};

const ChangePaymentStep = (
  {
    // pickGift,
    // newOrder,
    // setNewOrder,
    // handleChangeGift,
    // pickerData,
    // setPickerData,
    // paymentData,
    // setPaymentData,
    // isHidePrice,
  }
) => {
  const {
    pickGift,
    newOrder,
    setNewOrder,
    handleChangeGift,
    pickerData,
    setPickerData,
    paymentData,
    setPaymentData,
    giftInfo,
  } = useGift();
  const [changePending, setChangePending] = useState(false);
  const [productData, setProductData] = useState({});
  const [recalPending, setRecalPending] = useState(false);
  const { addToast } = useToast();
  const location = useLocation();
  const { updateQueryParams } = useUrlQuery();

  const urlSP = new URLSearchParams(location.search);
  const scrapeUrl = urlSP.get("scrapeUrl");
  const specIndex = urlSP.get("specIndex");
  const step = urlSP.get("step");
  const amount = urlSP.get("amount");

  const debounceSpec = useDebounce(specIndex, 200);
  const debounceAmount = useDebounce(amount, 200);

  useEffect(() => {
    !newOrder?.products[0] && updateQueryParams({ step: "search" });
  }, [newOrder]);

  // useEffect recal order
  useEffect(() => {
    if (step !== GIFTSTEPCHANGE || !scrapeUrl || !amount) return;

    const reCal = async (
      productId,
      specIndex,
      amount,
      baseOnId,
      isFreeServiceFee
    ) => {
      try {
        setRecalPending(true);
        let result = await calFee({
          productId,
          amount,
          specIndex,
          isFreeServiceFee,
          baseOn: baseOnId,
        });
        setNewOrder(result);
        setProductData(result.products[0].productId);
      } catch (err) {
        addToast(err.message, { appearance: "error" });
      } finally {
        setRecalPending(false);
      }
    };

    reCal(scrapeUrl, specIndex, amount, pickGift?._id, true);
  }, [debounceSpec, debounceAmount, pickGift, scrapeUrl]);

  const spread = useMemo(() => {
    const tpData = newOrder?.products?.[0];

    if (!tpData) return 0;

    const oldProduct = pickGift?.products[0];

    if (!oldProduct) return 0;

    const oldValue =
      oldProduct.itemPrice * oldProduct.amount + pickGift.fee?.shipping;

    const newValue = tpData.itemPrice * tpData.amount + newOrder.fee.shipping;

    return parseInt(newValue - oldValue);
  }, [newOrder]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setChangePending(true);

      await handleChangeGift(pickGift.tradeNo, newOrder);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    } finally {
      setChangePending(false);
    }
  };

  if (!newOrder) return <div>newOrder not found</div>;
  if (!pickGift?._id) return <div>pickGift not found</div>;

  return (
    <div>
      <section id="ecpay-form"></section>
      <Form onSubmit={handleSubmit}>
        <OrderCardWithOption
          order={newOrder}
          // productData={newOrder.products[0].productId}
          productData={productData}
          amountCb={(amt) => {
            let newProducts = [...newOrder.products];

            newProducts[0].amount = amt;

            setNewOrder((pre) => {
              return {
                ...pre,
                products: newProducts,
              };
            });
            updateQueryParams({ amount: amt });
          }}
          // amountCb={(amt) => updateQueryParams({ amount: amt })}
          specCb={(specIndex) => {
            let newProducts = [...newOrder.products];

            newProducts[0].specIndex = parseInt(specIndex);

            setNewOrder((pre) => {
              return {
                ...pre,
                products: newProducts,
              };
            });

            updateQueryParams({ specIndex });
          }}
          // specCb={(specIndex) => updateQueryParams({ specIndex })}
        />
        <br />
        <PayAmountDetail
          // productId={productId}
          // amount={amount}
          // specIndex={specIndex}
          // isFreeServiceFee={true}
          // baseOn={pickGift}
          isHidePrice={giftInfo?.isHidePrice}
          order={newOrder}
          pending={recalPending}
        />
        <br />

        <GiftPickForm data={pickerData} cb={(data) => setPickerData(data)} />
        <br />
        {spread > 0 && (
          <section>
            <MarkupNote />
            <br />
            <MarkUpForm
              paymentData={paymentData}
              setPaymentData={setPaymentData}
            />
          </section>
        )}
        <br />
        <Row className="justify-content-center">
          <Col xs="auto">
            <Button type="submit" disabled={changePending}>
              {spread > 0 ? "加價更改" : "免費更改"}
              {changePending && <Spinner size="sm" animation="border" />}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const GiftStep = (
  {
    // sender, gifts = [], setPickGift, isHidePrice
  }
) => {
  const { updateQueryParams } = useUrlQuery();
  const { giftInfo, setPickGift } = useGift();
  const sender = giftInfo?.sender;
  const gifts = giftInfo?.orderIDs;
  const isHidePrice = giftInfo?.isHidePrice;

  if (!giftInfo) return <div></div>;

  return (
    <>
      <Row>
        <Col xs="auto">
          <h3>
            <b>{sender?.displayName || gifts[0]?.customerData?.name} </b>
            送給您的禮物
          </h3>
          <small className="text-muted">接收禮物填寫收件地址即可完成</small>
        </Col>
      </Row>

      <hr />
      <Stack className="col-md-5 mx-auto">
        {gifts.map((e, idx) => (
          <GiftCard
            isHidePrice={isHidePrice}
            giftData={e}
            handleCheck={() => {
              setPickGift(e);
              // setCheckShow(true);
              // setStep("check");
              updateQueryParams({ step: GIFTSTEPCHECK, pickOrder: e._id });
            }}
            handleChange={() => {
              setPickGift(e);
              // setChangeShow(true);
              updateQueryParams({
                step: GIFTSTEPSEARCH,
                pickOrder: e._id,
              });
            }}
          />
        ))}
      </Stack>
    </>
  );
};

const GiftResBreadcrumbs = ({ currentStep }) => {
  const [stepIdx, setStepIdx] = useState(0);
  const checkSteps = ["收件資訊", "完成收禮"];
  const changeSteps = ["搜尋商品", "確認商品", "收件資訊/付款", "完成收禮"];
  useEffect(() => {
    switch (currentStep) {
      case GIFTSTEPCHECK:
        setStepIdx(0);
        break;
      case GIFTSTEPSEARCH:
        setStepIdx(0);
        break;
      case GIFTSTEPSCRAPE:
        setStepIdx(1);
        break;
      case GIFTSTEPCHANGE:
        setStepIdx(2);
        break;
    }
  }, [currentStep]);

  return (
    <StepProgress
      steps={currentStep === GIFTSTEPCHECK ? checkSteps : changeSteps}
      currentStep={stepIdx}
    />
  );
};

export const GiftResPageO = () => {
  //   giftInfo: {
  //     from:{email,name,_id},
  //     gifts:[{order}],
  //     changedGift: order
  //    }
  const [giftInfo, setGiftInfo] = useState(null);
  const [pending, setPending] = useState(false);
  const [pickerData, setPickerData] = useState({
    name: "",
    phone: "",
    email: "",
    address: {
      city: "",
      district: "",
      road: "",
    },
    message: "",
  });
  const [paymentData, setPaymentData] = useState({
    provider: "ecpay",
  });
  const [newOrder, setNewOrder] = useState(null);
  const [pickGift, setPickGift] = useState(null);
  const [step, setStep] = useState("gift");
  const location = useLocation();
  const history = useHistory();
  const { insertHTMLAndSubmit } = useECPay();
  const { addToast } = useToast();
  const giftToken = useMemo(
    () => new URLSearchParams(location.search).get("e"),
    [location]
  );

  const giftSpreadDiscount = useMemo(() => {
    if (!pickGift) return null;
    return pickGift.totalProductPrice;
  }, [pickGift]);

  // Fetch gift infomation
  useEffect(() => {
    setPending(true);

    fetchGiftInfoV2(giftToken)
      .then((res) => {
        let newGiftInfo = res;

        newGiftInfo.gifts = res.orderIDs;

        let { status, msg } = handleRes(newGiftInfo);

        newGiftInfo.status = status;

        newGiftInfo.msg = msg;

        setGiftInfo(newGiftInfo);
      })
      .catch((err) => setGiftInfo({ status: "err", msg: err.message }))
      .finally(() => setPending(false));

    // fetchGiftInfo(giftToken)
    //   .then((res) => {
    //     let { status, msg } = handleRes(res);
    //     res.status = status;
    //     res.msg = msg;
    //     setGiftInfo(res);
    //   })
    //   .catch((err) => setGiftInfo({ status: "err", msg: err.message }))
    //   .finally(() => setPending(false));
  }, []);

  // Redirect to gift result page
  useEffect(() => {
    if (!giftInfo) return;

    if (giftInfo.status == "checked")
      history.push(`/giftRes/result?o=${giftInfo.gifts[0]._id}`);

    if (giftInfo.status == "change")
      history.push(`/giftRes/result?o=${giftInfo.changedGift._id}`);
  }, [giftInfo]);

  // Listen url query status
  useEffect(() => {
    if (giftInfo?.status !== "get") return;

    const searchParams = new URLSearchParams(location.search);
    let currentStep = searchParams.get("step");
    setStep(currentStep || "gift");

    let pickOrderId = searchParams.get("pickOrder");
    if (pickOrderId)
      setPickGift(giftInfo.gifts.find((e) => e._id === pickOrderId));
  }, [location, giftInfo]);

  const handleRes = (res) => {
    let allStatus = res.gifts.map((e) => e.status);

    if (allStatus.includes("checked"))
      return { status: "checked", msg: "禮物已被兌換" };

    if (allStatus.includes("expired"))
      return { status: "expired", msg: "禮物已過期" };

    if (allStatus.includes("refund"))
      return { status: "refund", msg: "禮物失效" };

    if (allStatus.includes("changeGift"))
      return { status: "change", msg: "禮物已被更改" };

    if (!allStatus.every((e) => e === "get"))
      return { status: "err", msg: "未知錯誤" };

    return { status: "get", msg: "可兌換" };
  };

  const handleCheck = async (pw = "") => {
    try {
      // if (giftInfo.pw && !pw.trim()) throw new Error("需要輸入暗號");
      let checkData = {
        ...pickGift,
        gift: { picker: pickerData },
      };

      let result = await check(
        giftToken,
        { pickTradeNo: pickGift.tradeNo, pw },
        checkData
      );

      let newGiftInfo = { ...giftInfo };

      newGiftInfo.gifts = [result];

      const { status, msg } = handleRes(newGiftInfo);

      newGiftInfo.status = status;

      newGiftInfo.msg = msg;

      setGiftInfo(newGiftInfo);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const handleChangeGift = async (pickTradeNo, changeGiftData) => {
    try {
      let result = {};

      const payload = {
        giftToken,
        giftParam: { pickTradeNo, pw: "" },
        changeGift: {
          ...changeGiftData,
          gift: { picker: pickerData },
          payment: paymentData,
        },
      };

      result = await changeGift(payload);

      // mark down
      if (result?._id) {
        let newGiftInfo = { ...giftInfo };
        newGiftInfo.changedGift = result;
        newGiftInfo.status = "change";
        setGiftInfo(newGiftInfo);
        return;
      }

      // mark up
      await insertHTMLAndSubmit(result);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  if (!giftToken) return <EmptyPage title="沒有禮物ID" />;

  if (pending) return <LoadingSection />;

  if (!giftInfo) return <EmptyPage title="沒有此禮物" />;

  if (["err", "expired", "refund"].includes(giftInfo?.status))
    return <EmptyPage title={giftInfo.msg} />;

  // if (giftInfo?.status === "checked")
  //   return <GiftResult targetOrder={giftInfo.gifts[0]} />;
  // if (giftInfo?.status === "change")
  //   return <GiftResult targetOrder={giftInfo.changedGift} />;

  return (
    <div>
      {step !== "gift" && (
        <section>
          <Button
            variant="outline"
            onClick={() => history.goBack()}
            className="gift-res-back-step-arrow"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>返回上一步</span>
          </Button>

          <br />
          <GiftResBreadcrumbs currentStep={step} />
        </section>
      )}
      {[GIFTSTEPSEARCH, GIFTSTEPSCRAPE, GIFTSTEPCHANGE].includes(step) && (
        <GiftResChangeNote />
      )}
      <br />
      {/* Gift Step */}
      {step === "gift" && (
        <GiftStep
          gifts={giftInfo.gifts}
          setPickGift={setPickGift}
          isHidePrice={giftInfo.isHidePrice}
        />
      )}
      {step === GIFTSTEPCHECK && (
        <CheckStep
          pickerData={pickerData}
          setPickerData={setPickerData}
          handleCheck={handleCheck}
        />
      )}
      {step === GIFTSTEPSEARCH && (
        <SearchGiftStep giftSpreadDiscount={giftSpreadDiscount} />
      )}
      {step === GIFTSTEPSCRAPE && (
        <ScrapeProductStep
          setNewOrder={setNewOrder}
          giftSpreadDiscount={giftSpreadDiscount}
        />
      )}
      {step === GIFTSTEPCHANGE && (
        <ChangePaymentStep
          pickGift={pickGift}
          isHidePrice={giftInfo.isHidePrice}
          newOrder={newOrder}
          setNewOrder={setNewOrder}
          handleChangeGift={handleChangeGift}
          pickerData={pickerData}
          setPickerData={setPickerData}
          paymentData={paymentData}
          setPaymentData={setPaymentData}
        />
      )}
    </div>
  );
};

export const GiftResPage = () => {
  const location = useLocation();
  const history = useHistory();
  const step = new URLSearchParams(location.search).get("step");

  return (
    <GiftProvider>
      {step && (
        <section>
          <Button
            variant="outline"
            onClick={() => history.goBack()}
            className="gift-res-back-step-arrow"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>返回上一步</span>
          </Button>

          <br />
          <GiftResBreadcrumbs currentStep={step} />
        </section>
      )}
      {[GIFTSTEPSEARCH, GIFTSTEPSCRAPE, GIFTSTEPCHANGE].includes(step) && (
        <GiftResChangeNote />
      )}
      <br />
      {/* Gift Step */}
      {step ? null : (
        <div className={`step-gift`}>
          <GiftStep />
        </div>
      )}

      {/* Check Step */}
      {step === GIFTSTEPCHECK && (
        <div className="step-check">
          <CheckStep />
        </div>
      )}
      {step === GIFTSTEPSEARCH && (
        <div className="step-search">
          <SearchGiftStep />
        </div>
      )}
      {step === GIFTSTEPSCRAPE && (
        <div className="step-search">
          <ScrapeProductStep />
        </div>
      )}
      {step === GIFTSTEPCHANGE && (
        <div className="step-change">
          <ChangePaymentStep />
        </div>
      )}
    </GiftProvider>
  );
};

export const GiftResult = ({ targetOrder }) => {
  const [order, setOrder] = useState(targetOrder);
  const [pending, setPending] = useState(false);

  const location = useLocation();
  const orderId = useMemo(
    () => new URLSearchParams(location.search).get("o"),
    [location]
  );
  useEffect(() => {
    if (!orderId) return;
    setPending(true);
    fetchGiftAnonymous(orderId)
      .then((res) => setOrder(res))
      .catch((err) => console.log(err.message))
      .finally(() => setPending(false));
  }, []);

  if (pending) return <LoadingSection />;

  if (!order?._id) return <EmptyPage title={"查無禮物"} />;

  if (
    order.status !== process.env["REACT_APP_COUPON_STATUS_CHECK"] &&
    order.status !== process.env["REACT_APP_COUPON_STATUS_CHANGEGIFT"]
  )
    return <EmptyPage title={"收禮未完成，請點送禮連結"} />;

  return (
    <Stack className="col-md-8 mx-auto">
      <section className="text-center">
        <h3>
          <strong>收禮訂單</strong>
        </h3>
      </section>
      <OrderRecord record={order} />
    </Stack>
  );
};
