import { useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCreditCard,
  faLock,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Form,
  ListGroup,
  Tabs,
  Tab,
  Row,
  Col,
  Stack,
  Card,
  Spinner,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDebounce } from "Hook/useDebounce";
import { validPhoneBarCode, calFee, validBusinessNo } from "./utils";
import { LocationInput } from "Location/Location";
import cityList from "static/city-list.json";
import { useToast } from "Provider/ToastProvider";
import "./component.css";

export const UserDataForm = ({ data = {}, cb }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    let timer = setTimeout(() => cb({ name, phone, email }), 300);

    return () => clearTimeout(timer);
  }, [name, phone, email]);

  useEffect(() => {
    setEmail(data?.email || "");
    setName(data?.name || "");
    setPhone(data?.phone || "");
  }, [data]);

  return (
    <div>
      <Form.Floating>
        <Form.Control
          value={name}
          id="name"
          className="name my-1"
          onChange={(e) => setName(e.target.value)}
          placeholder="姓名"
          required
        />
        <label className="required" htmlFor="name">
          姓名
        </label>
      </Form.Floating>

      <Form.Floating>
        <Form.Control
          value={email}
          id="email"
          className="email my-1"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-Mail"
          required
        />
        <label className="required" htmlFor="email">
          Email
        </label>
      </Form.Floating>
      <Form.Floating>
        <Form.Control
          value={phone}
          id="phone"
          className="phone my-1"
          onChange={(e) => setPhone(e.target.value)}
          placeholder="手機"
          required
        />

        <label className="required" htmlFor="phone">
          手機
        </label>
      </Form.Floating>
    </div>
  );
};

export const PaymentProvider = ({ data, cb }) => {
  const [provider, setProvider] = useState(data);

  useEffect(() => {
    cb(provider);
  }, [provider]);

  return (
    <>
      <ListGroup>
        <ListGroup.Item
          onClick={() => setProvider("ecpay")}
          className="d-flex justify-content-center align-items-center"
        >
          <FontAwesomeIcon icon={faCreditCard} />
          信用卡
          {provider === "ecpay" ? <FontAwesomeIcon icon={faCheck} /> : ""}
        </ListGroup.Item>
      </ListGroup>
      <Stack className="my-2 text-center">
        <small className="text-muted">
          <FontAwesomeIcon icon={faLock} className="text-success" />
          本平台使用綠界第三方支付，可安心付款
        </small>
      </Stack>
    </>
  );
};

export const InvoiceForm = ({ cb }) => {
  const [carrierType, setCarrierType] = useState("1");
  const [showBusiness, setShowBusiness] = useState(false);
  const [phoneBarCode, setPhoneBarCode] = useState("");
  const [digit, setDigit] = useState("");
  const [businessNo, setBusinessNo] = useState("");
  const debouncePhoneBarCode = useDebounce(phoneBarCode, 150);
  const debounceDigit = useDebounce(digit, 150);
  const debounceBusinessNo = useDebounce(businessNo, 150);

  const isValidPhoneBar = useMemo(() => {
    if (!debouncePhoneBarCode) return { valid: false, msg: "請輸入手機載具" };

    let isValid = /^\/[0-9A-Z.+-]{7}$/.test(debouncePhoneBarCode);

    if (!isValid) return { valid: false, msg: "格式錯誤" };

    return { valid: true, msg: "" };
  }, [debouncePhoneBarCode]);

  const isValidBusinessNo = useMemo(() => {
    if (!debounceBusinessNo) return { valid: true, msg: "" };
    return validBusinessNo(debounceBusinessNo);
  }, [debounceBusinessNo]);

  useEffect(
    () => cb({ businessNo, phoneBarCode, digit, carrierType }),
    [debounceBusinessNo, debounceDigit, debouncePhoneBarCode, carrierType]
  );

  const handlePhoneBarCodeChange = (e) => {
    let value = e.target.value.trim();

    if (value.length > 8) return;

    if (!value.startsWith("/")) value = "/" + value;

    setPhoneBarCode(value.toUpperCase());
  };

  return (
    <div>
      <Tabs
        variant="pills"
        fill
        defaultActiveKey="1"
        id="invoice-form"
        className="mb-3"
        onSelect={(activeKey) => {
          setCarrierType(activeKey);
        }}
      >
        <Tab eventKey="1" title="雲端發票">
          <Stack direction="horizontal" className="px-1">
            <strong>統一編號</strong>
            <Form.Switch
              className="ms-auto"
              checked={showBusiness}
              onChange={(e) => setShowBusiness(e.target.checked)}
            />
          </Stack>
          {showBusiness && (
            <Form.Group controlId="businessNo">
              <Form.Control
                value={businessNo}
                isValid={businessNo && isValidBusinessNo.valid}
                isInvalid={!isValidBusinessNo.valid}
                onChange={(e) => {
                  if (e.target.value.length > 8) return;
                  setBusinessNo(e.target.value);
                }}
                placeholder="統一編號"
              />

              <Form.Control.Feedback type="invalid">
                {isValidBusinessNo.msg}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </Tab>
        <Tab eventKey="3" title="手機載具">
          <Form.Control
            isValid={isValidPhoneBar.valid}
            isInvalid={!isValidPhoneBar.valid}
            value={phoneBarCode}
            placeholder="手機載具"
            onChange={(e) => handlePhoneBarCodeChange(e)}
            max={8}
          />
          <Form.Control.Feedback type="invalid">
            {isValidPhoneBar.msg}
          </Form.Control.Feedback>
        </Tab>
        <Tab eventKey="2" title="自然人憑證">
          <Form.Control
            value={digit}
            placeholder="自然人憑證"
            onChange={(e) => setDigit(e.target.value)}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export const DiscountCodeForm = ({ cb, isConfirm, cancelCB }) => {
  const [discountCode, setDiscountCode] = useState("");
  const [cancelPending, setCancelPending] = useState(false);
  useEffect(() => {
    cb(discountCode);
  }, [discountCode]);

  const handleCancel = async () => {
    setCancelPending(true);
    try {
      await cancelCB();
      setDiscountCode("");
    } catch (err) {
      console.log(err);
    } finally {
      setCancelPending(false);
    }
  };

  return (
    <Row className="align-items-center">
      <Col>
        <Form.Floating>
          <Form.Control
            id="name"
            className="discount my-1"
            value={discountCode}
            onChange={(e) =>
              setDiscountCode(e.target.value.trim().toLowerCase())
            }
            placeholder="輸入優惠碼"
            disabled={isConfirm}
          />
          <label htmlFor="discount">優惠碼</label>
        </Form.Floating>
      </Col>
      {isConfirm && (
        <Col xs="auto">
          <Button
            variant="outline-danger"
            onClick={handleCancel}
            disabled={cancelPending}
          >
            {cancelPending && <Spinner size="sm" />}
            取消
          </Button>
        </Col>
      )}
    </Row>
  );
};

export const GiftPickForm = ({ data, cb }) => {
  const [name, setName] = useState(data?.name);
  const [phone, setPhone] = useState(data?.phone);
  const [email, setEmail] = useState(data?.email);
  const [address, setAddress] = useState({
    city: data?.address?.city,
    district: data?.address?.district,
    road: data?.address?.road,
  });
  const [message, setMessage] = useState(data?.message);

  const [cityOptions, setCityOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [showCity, setShowCity] = useState(false);
  const [showDis, setShowDis] = useState(false);
  const cityInput = useRef(null);
  const districtInput = useRef(null);
  const cityBlurTimeoutRef = useRef(null); // 用於存儲 setTimeout 的引用
  const districtBlurTimeoutRef = useRef(null); // 用於存儲 setTimeout 的引用
  const unsupportCity = [
    "連江縣",
    "釣魚臺",
    "釣魚台",
    "澎湖縣",
    "金門縣",
    "南海島",
  ];

  const handleAddressInput = ({ address }) => {
    let addressArr = address.split(",");
    const country = addressArr.pop();
    const city = addressArr.pop();
    const district = addressArr.pop();
    const road = addressArr.join();

    const result = { city, district, road };
    console.log(result);
    setAddress(result);
  };

  const isInvalidCity = useMemo(() => {
    if (!address.city) return false;

    if (Object.keys(cityList).every((e) => e !== address.city))
      return "city list";

    if (unsupportCity.some((e) => e === address.city)) return "unsupport list";
    return false;
  }, [address]);

  useEffect(() => {
    let timer = setTimeout(
      () => cb({ name, phone, email, address, message }),
      300
    );

    return () => clearTimeout(timer);
  }, [name, phone, email, address, message]);

  // Change city options
  useEffect(() => {
    if (!address?.city) return;
    if (Object.keys(cityList).find((e) => e === address?.city)) {
      setCityOptions([]);
      if (cityList[address.city].find((e) => e === address.district)) {
        // setShowDis(false);
        setDistrictOptions([]);
        return;
      }
      let districtTextPattern = new RegExp(
        address.district.split("").join("|")
      );
      let matchDistrict = cityList[address.city].filter((e) =>
        e.match(districtTextPattern)
      );

      setDistrictOptions(matchDistrict);

      return;
    }

    // Search city recommend
    let pattern = new RegExp(address.city.split("").join("|"));
    let matchCity = Object.keys(cityList).filter((e) => e.match(pattern));
    setCityOptions(matchCity);
  }, [address]);

  // Set input focus listener
  useEffect(() => {
    const handleCityFocus = () => {
      clearTimeout(cityBlurTimeoutRef.current); // 清除之前設定的延遲
      setShowCity(true);
    };
    const handleCityBlur = () => {
      cityBlurTimeoutRef.current = setTimeout(() => {
        setShowCity(false);
      }, 100);
    };

    const handleDistrictFocus = () => {
      clearTimeout(districtBlurTimeoutRef.current); // 清除之前設定的延遲
      setShowDis(true);
    };

    const handleDistrictBlur = () => {
      districtBlurTimeoutRef.current = setTimeout(() => {
        setShowDis(false);
      }, 100);
    };
    // 添加事件監聽器以處理焦點變化
    cityInput.current.addEventListener("focus", handleCityFocus);
    cityInput.current.addEventListener("blur", handleCityBlur);
    districtInput.current.addEventListener("focus", handleDistrictFocus);
    districtInput.current.addEventListener("blur", handleDistrictBlur);

    // 記得在組件卸載時清除事件監聽器
    return () => {
      cityInput.current?.removeEventListener("focus", handleCityFocus);
      cityInput.current?.removeEventListener("blur", handleCityBlur);

      districtInput.current?.removeEventListener("focus", handleDistrictFocus);
      districtInput.current?.removeEventListener("blur", handleDistrictBlur);
      clearTimeout(cityBlurTimeoutRef.current); // 清除之前設定的延遲
    };
  }, []);

  return (
    <div>
      <h5>
        <strong>收件人</strong>
      </h5>
      <hr />
      <Row xs={1} md={3}>
        <Col>
          <Form.Floating>
            <Form.Control
              value={name || ""}
              id="name"
              className="name my-1"
              onChange={(e) => setName(e.target.value)}
              placeholder="姓名"
              required
            />
            <label htmlFor="name" className="required">
              姓名
            </label>
          </Form.Floating>
        </Col>
        <Col>
          <Form.Floating>
            <Form.Control
              value={phone || ""}
              id="phone"
              className="phone my-1"
              onChange={(e) => setPhone(e.target.value)}
              placeholder="手機"
              required
            />
            <label htmlFor="phone" className="required">
              手機
            </label>
          </Form.Floating>
        </Col>
        <Col>
          <Form.Floating>
            <Form.Control
              value={email || ""}
              id="email"
              type="email"
              className="email my-1"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-Mail"
              required
            />
            <label htmlFor="email" className="required">
              Email
            </label>
          </Form.Floating>
        </Col>
      </Row>
      <br />
      <Stack>
        <h5>
          <strong>地址</strong>
        </h5>
        <small className="text-muted">本平台不提供離島配送</small>
      </Stack>
      <hr />

      <Row xs={1} md={3}>
        <Col className="my-1">
          <Form.Floating>
            <Form.Control
              id="city"
              value={address.city || ""}
              onChange={(e) => setAddress({ ...address, city: e.target.value })}
              placeholder="縣市"
              required
              ref={cityInput}
              isInvalid={isInvalidCity}
            />
            <label htmlFor="city" className="required">
              縣市
            </label>
            {showCity && (
              <ul className={`address-dropdown`}>
                {cityOptions.map((e) => (
                  <li
                    value={e}
                    key={e}
                    onClick={() => setAddress({ ...address, city: e })}
                  >
                    {e}
                  </li>
                ))}
              </ul>
            )}
            <Form.Control.Feedback type="invalid">
              請輸入正確的地址且本平台不提供離島配送
            </Form.Control.Feedback>
          </Form.Floating>
        </Col>
        <Col className="my-1">
          <Form.Floating>
            <Form.Control
              id="district"
              value={address.district || ""}
              onChange={(e) =>
                setAddress({ ...address, district: e.target.value })
              }
              placeholder="鄉鎮"
              required
              ref={districtInput}
            />
            <label htmlFor="district" className="required">
              鄉鎮
            </label>
            <ul className={`address-dropdown ${!showDis && "d-none"}`}>
              {districtOptions?.map((e) => (
                <li
                  value={e}
                  key={e}
                  onClick={() => setAddress({ ...address, district: e })}
                >
                  {e}
                </li>
              ))}
            </ul>
          </Form.Floating>
        </Col>
        <Col className="my-1">
          <Form.Floating>
            <Form.Control
              value={address?.road || ""}
              id="road"
              className="road"
              onChange={(e) => setAddress({ ...address, road: e.target.value })}
              placeholder="地址"
              required
            />
            <label htmlFor="road" className="required">
              地址
            </label>
          </Form.Floating>
        </Col>
      </Row>
      <br />
      <h5>
        <strong>備註</strong>
      </h5>
      <hr />
      <Form.Control
        value={message || ""}
        id="message"
        className="message my-1"
        onChange={(e) => setMessage(e.target.value)}
        placeholder="如：客製化文字"
      />
    </div>
  );
};

export const PayAmountDetail = ({ order, pending, isHidePrice }) => {
  
  const FeeLabel = ({
    title,
    value,
    isDel = false,
    textC = "text-primary",
  }) => {
    return (
      <Row className="align-items-center py-1">
        <Col>
          <strong className="me-auto">{title}</strong>
        </Col>
        <Col xs="auto">
          {pending ? (
            <Spinner className="text-primary" size="sm" />
          ) : (
            <strong className={`${textC} ${isDel && "del-price"}`}>
              NT$ {value}
            </strong>
          )}
        </Col>
      </Row>
    );
  };

  const HidePriceNotice = () => {
    return (
      <Stack direction="horizontal" className="text-muted">
        <FontAwesomeIcon icon={faLock} className="ms-auto" />
        <small>：送禮人隱藏禮物價格</small>
      </Stack>
    );
  };

  if (!order) return <div></div>;

  if (order?.gift?.baseOn?._id) {
    return (
      <Card className="fee">
        <Card.Header
          style={{
            background: "linear-gradient(90deg, #1CB5E0 0%, #000851 100%)",
          }}
          className="text-center text-white"
        >
          <strong>{order.giftSpread > 5 ? "需補差額" : "免費更改"}</strong>
        </Card.Header>
        <Card.Body>
          {isHidePrice && <HidePriceNotice />}
          <FeeLabel
            title="商品原價"
            value={
              isHidePrice ? (
                <FontAwesomeIcon icon={faLock} className="ms-1" />
              ) : (
                order.totalProductPrice
              )
            }
            // textC="text-success"
          />
          <FeeLabel
            title="運費"
            value={
              isHidePrice ? (
                <FontAwesomeIcon icon={faLock} className="ms-1" />
              ) : (
                order.shippingFeeAmount
              )
            }
            // textC="text-success"
          />
          <FeeLabel
            title="原運費減免"
            value={
              isHidePrice ? (
                <FontAwesomeIcon icon={faLock} className="ms-1" />
              ) : (
                -order.gift.baseOn.shippingFeeAmount
              )
            }
            textC="text-success"
          />
          <FeeLabel
            title="原禮物減免"
            value={
              isHidePrice ? (
                <FontAwesomeIcon icon={faLock} className="ms-1" />
              ) : (
                -order.gift.baseOn.totalProductPrice
              )
            }
            textC="text-success"
          />
          <hr />
          <FeeLabel title="商品差額" value={order.productSpread} />
          <FeeLabel title="運費差額" value={order.shippingSpread} />
          <hr />
          <FeeLabel
            title="需補差額"
            value={order.giftSpread > 0 ? order.giftSpread : 0}
          />
        </Card.Body>
      </Card>
    );
  }
  return (
    <Card className="fee">
      <Card.Body>

        <FeeLabel title="商品價格" value={order.totalProductPrice} />
        <FeeLabel title="運費" value={order.shippingFeeAmount} />
        {/* <FeeLabel title="手續費" value={order.serviceFeeAmount} /> */}
        <hr />
        {order.discountCode && (
          <>
            <FeeLabel
              title={"優惠碼折扣"}
              value={order.discountAmount}
              textC="text-success"
            />
            <hr />
          </>
        )}
        <FeeLabel title="總共" value={order.paymentAmount} />
      </Card.Body>
    </Card>
  );
};
