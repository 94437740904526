import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col } from "react-bootstrap";
import {
  faChevronCircleRight,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import "./ScrollController.css"

export const InfiniteScroller = ({ images }) => {
  return (
    <div className="carousel">
      <div className="carousel-track">
        {images.concat(images).map((image, index) => (
          <img className="px-5" key={index} src={image} alt={`Slide ${index}`} />
        ))}
      </div>
    </div>
  );
};

export const ScrollController = ({ children, align = "start" }) => {
  const scrollElement = useRef(null);

  const handleScrollMove = (ele, isRight) => {
    let currentBoundary = ele.current.scrollLeft;

    let moveTo = isRight ? currentBoundary + 500 : currentBoundary - 500;

    ele.current.scrollTo({ left: moveTo, behavior: "smooth" });
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          overflow: "scroll",
          scrollSnapType: "x mandatory",
          alignItems: align,
        }}
        className="scroller"
        ref={scrollElement}
      >
        {children}
        <section
          style={{
            position: "absolute",
            left: 0,
            top: "40%",
          }}
          className="scroll-controller"
        >
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            size="2x"
            style={{
              color: "#f4f5f5",
              backgroundColor: "#7f7a76",
              borderRadius: "50%",
              opacity: 0.8,
            }}
            onClick={() => {
              handleScrollMove(scrollElement, false);
            }}
          />
        </section>
        <section
          style={{ position: "absolute", right: "2%", top: "40%" }}
          className="scroll-controller"
        >
          <FontAwesomeIcon
            icon={faChevronCircleRight}
            size="2x"
            style={{
              color: "#f4f5f5",
              backgroundColor: "#7f7a76",
              borderRadius: "50%",
              opacity: 0.8,
            }}
            onClick={() => {
              handleScrollMove(scrollElement, true);
            }}
          />
        </section>
      </div>
    </div>
  );
};

export const ScrollControllerItem = ({ children }) => {
  return (
    <Col
      xs="auto"
      style={{
        scrollSnapAlign: "start",
        scrollSnapStop: "always",
      }}
      className="px-1"
    >
      {children}
    </Col>
  );
};

export const SwipeScrollContainer = ({ children }) => {
  const containerRef = useRef(null);

  const handleTouchStart = (e) => {
    containerRef.current.dataset.touchStartX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (!containerRef.current.dataset.touchStartX) return;

    const touchStartX = parseInt(containerRef.current.dataset.touchStartX);
    const currentX = e.touches[0].clientX;
    const diff = touchStartX - currentX;

    containerRef.current.scrollLeft += diff;
    containerRef.current.dataset.touchStartX = currentX;
  };

  const handleTouchEnd = () => {
    delete containerRef.current.dataset.touchStartX;
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        ref={containerRef}
        style={{
          display: 'flex',
          overflowX: 'auto',
          scrollSnapType: 'x mandatory',
          scrollBehavior: 'smooth',
          WebkitOverflowScrolling: 'touch',
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {children}
      </div>
    </div>
  );
};

export const SwipeScrollItem = ({ children }) => {
  return (
    <div
      style={{
        flexShrink: 0,
        scrollSnapAlign: 'start',
        scrollSnapStop: 'always',
      }}
    >
      {children}
    </div>
  );
};
