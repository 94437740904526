import { useMemo, useState, useRef } from "react";
import {
  faCopy,
  faGift,
  faPen,
  faTimes,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import {
  Badge,
  Card,
  Row,
  Col,
  Image,
  Form,
  Stack,
  Button,
  ProgressBar,
  Accordion,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useClipboard } from "Hook/Clipboard/useClipboard";
import { getCouponStatus, refundOrder } from "./utils";
import { calPrice, calLimitAmt } from "Components/Product/utils";
import { OverflowText, OverflowTextLine } from "Components/Text/OverflowText";
import { useToast } from "Provider/ToastProvider";
import { PlatformBadge, SpecSelector } from "Components/Product/component";
import { GiftReqModal } from "User/Gift/GiftPage";
export const AmtContrl = ({ value, max, mCb, pCb }) => {
  return (
    <Stack direction="horizontal" gap={2}>
      <Button
        size="sm"
        variant={value <= 1 ? "outline-secondary" : "outline-primary"}
        // style={{ borderRadius: "50%" }}
        onClick={mCb}
        disabled={value <= 1}
      >
        <FontAwesomeIcon icon={faMinus} />
      </Button>

      <strong>{value}</strong>
      <Button
        size="sm"
        variant={value >= max ? "outline-secondary" : "outline-primary"}
        // style={{ borderRadius: "50%" }}
        onClick={pCb}
        disabled={value >= max}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </Stack>
  );
};

// export const CustomOptionsSection = ({
//   pickCustomOptions,
//   setPickCustomOptions,
// }) => {
//   return (
//     <section>
//       <Row>
//         {pickCustomOptions.map((co, idx) => (
//           <Col>
//             <Form.Control
//               value={co}
//               placeholder={`客製化 ${idx}`}
//               onChange={(e) => {
//                 let newCustomOptions = [...pickCustomOptions];
//                 newCustomOptions[idx] = e.target.value;
//                 setPickCustomOptions(newCustomOptions);
//               }}
//             />
//           </Col>
//         ))}
//       </Row>
//     </section>
//   );
// };

export const OrderShortCard = ({ order, cb }) => {
  const { products, sellerData } = order;
  const product = products[0];

  const specValue = useMemo(() => {
    const sg = product.productId?.specGroup;
    if (!sg) return null;
    return Object.values(sg[product.specIndex].specs).sort().join(" | ");
  }, [order]);

  return (
    <Card onClick={() => cb(order)}>
      <Card.Body>
        <Row className="justify-content-between">
          <Col xs="auto">
            <Image
              src={product.images[0]}
              width="100px"
              height="100px"
              rounded
            />
          </Col>

          <Col>
            <Stack className="productInfo" gap={1}>
              <strong>NT$ {order.finalPrice}</strong>
              <strong className="text-primary">{sellerData.name}</strong>
              <small>{product.productName}</small>
              {/* <OverflowText text={product.description} max="12" /> */}
              <small>{specValue}</small>
            </Stack>
          </Col>
          <Col xs="auto">
            <section className="amount-sec text-muted">
              <FontAwesomeIcon icon={faTimes} /> {product.amount}
            </section>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const OrderCardWithOption = ({
  order,
  productData,
  cb = () => {},
  specCb = () => {},
  amountCb = () => {},
  isAvaSwitchSpec = true,
}) => {
  const { products, sellerData } = order;
  const product = products[0];
  const { specGroup, noSpecStock, price } = productData;

  // const specValue = useMemo(() => {
  //   const sg = specGroup;
  //   if (!sg) return null;
  //   return Object.values(sg[product.specIndex].specs).sort().join(" | ");
  // }, [order]);

  // const isAvaSwitchSpec = useMemo(() => {
  //   if (!specGroup) return false;
  //   const difPrice = new Set(specGroup.map((e) => e.price));
  //   if (difPrice.size > 1) return false;
  //   return true;
  // }, [specGroup]);

  // cal final price
  // const fPrice = useMemo(() => {
  //   return calPrice(product.specIndex, product.amount, { specGroup, price });
  // }, [order]);

  // cal quantity that is able to buy
  const limitAmount = useMemo(() => {
    return calLimitAmt(product.specIndex, { specGroup, noSpecStock });
  }, [order]);

  const handleAmountChange = (newAmount) => {
    if (newAmount <= 0 || newAmount > limitAmount) return;
    amountCb(newAmount);
  };

  return (
    <Card onClick={() => cb(order)}>
      <Card.Body>
        <Row className="justify-content-between">
          <Col xs="auto">
            <Image
              src={product.images[0]}
              width="100px"
              height="100px"
              rounded
            />
            <section className="amount my-2">
              <AmtContrl
                value={product.amount}
                max={limitAmount}
                pCb={() => handleAmountChange(product.amount + 1)}
                mCb={() => handleAmountChange(product.amount - 1)}
              />
            </section>
          </Col>

          <Col>
            <section className="productInfo">
              <p className="text-primary">
                <strong>{sellerData.name}</strong>
              </p>
              <p>{product.productName}</p>
              <OverflowText text={product.description} maxLine={3} />

              {/* <p>{product.description}</p> */}
            </section>

            <section className="spec-sec">
              {isAvaSwitchSpec && specGroup ? (
                <SpecSelector
                  specGroup={specGroup}
                  pickSpec={product.specIndex}
                  setPickSpec={specCb}
                />
              ) : (
                <strong>{order.specTitle}</strong>
              )}
            </section>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="bg-white">
        <div>
          <strong>來源：</strong>
          <PlatformBadge platform={productData.platform} />
        </div>
      </Card.Footer>
    </Card>
  );
};

export const GiftSetting = ({ id, setting }) => {
  const [gs, setGS] = useState({
    pw: setting?.pw || "",
    note: setting?.note || "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const h = useHistory();

  const handleGiftReq = async () => {
    try {
      h.push(`/user/gift/req?addGift=${id}`);
      //   let data = {
      //     title: `NearMe-禮物`,
      //     // text: "NearMe的禮物",
      //     url: `${process.env['REACT_APP_DOMAIN']}/gift/receive/${id}`,
      //   };

      //   let result = await copyShare(data);

      //   if (!result) throw new Error("複製失敗");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Row>
        <Col>
          {isEdit ? (
            <section>
              <Form.Control
                value={gs.pw}
                onChange={(e) => setGS({ ...gs, pw: e.target.value })}
              />
              <Form.Control
                value={gs.note}
                onChange={(e) => setGS({ ...gs, note: e.target.value })}
              />
              <Button>update</Button>
            </section>
          ) : (
            <section className="gift-setting">
              <p>password: ***</p>
              <p>note: {gs.note}</p>
            </section>
          )}
        </Col>
        <Col xs="auto" onClick={() => setIsEdit(!isEdit)}>
          <FontAwesomeIcon icon={faPen} />
        </Col>
      </Row>
      <Button onClick={handleGiftReq}>gift</Button>
    </div>
  );
};

export const OrderRecord = ({ record, handleGiftReq }) => {
  const [order, setOrder] = useState(record);
  const [showGiftModal, setShowGiftModal] = useState(false);
  const { copyClipboard } = useClipboard();
  const history = useHistory();
  const seller = order.sellerData;
  const { addToast } = useToast();

  let statusBadge = useMemo(() => getCouponStatus(order.status), [order]);
  const baseOn = useMemo(() => order?.gift?.baseOn, [order]);

  const spreadDetail = useMemo(() => {
    return {
      productPrice: order.productSpread,
      shipping: order.shippingSpread,
      service: 0,
      gift: order.giftSpread,
      giftDiscount: baseOn ? baseOn.totalProductPrice + baseOn.fee.shipping : 0,
    };
  }, [order]);

  const shippingStatus = useMemo(() => {
    const s = order?.shipping?.status;
    if (s === process.env["REACT_APP_SHIPPING_STATUS_PENDING"]) return 0;
    if (s === process.env["REACT_APP_SHIPPING_STATUS_SHIPPING"]) return 1;
    if (s === process.env["REACT_APP_SHIPPING_STATUS_DELIVERD"]) return 2;
    if (s === process.env["REACT_APP_SHIPPING_STATUS_CANCELED"]) return 4;
    return -1;
  }, [order]);

  const PriceSection = ({ spread, isSpread = false, isService = true }) => {
    const Item = ({ title, price }) => {
      return (
        <Row>
          <Col xs="auto">
            <strong>{title}</strong>
          </Col>
          <Col>
            <hr />
          </Col>
          <Col xs="auto">
            <strong className="ms-auto">NT$ {price}</strong>
          </Col>
        </Row>
      );
    };

    // if (!baseOn) {
    //   return null;
    // }

    return (
      <div>
        <hr />
        <Accordion>
          <Accordion.Header>
            <strong>付款金額 NT$ {order.ecpayAmount}</strong>
          </Accordion.Header>
          <Accordion.Body>
            <Stack gap={1}>
              <Item title="商品總價" price={order.totalProductPrice} />
              <Item title="運費" price={order.shippingFeeAmount} />

              {/* {isService && (
                <Item title="服務費" price={order.serviceFeeAmount} />
              )} */}
              {order._discountCode && (
                <Item
                  title={order._discountCode.title}
                  price={order.discountAmount}
                />
              )}
              {isSpread && (
                <div>
                  {/* <Item title="商品差價" price={spread.productPrice} />
              <Item title="運費差價" price={spread.shipping} />
            <Item title="服務費差價" price={spread.service} /> */}
                  <Item title="禮物折價" price={-spread.giftDiscount} />
                </div>
              )}

              <Item title="付款金額" price={order.ecpayAmount} />
            </Stack>
          </Accordion.Body>
        </Accordion>
      </div>
    );
  };

  const ProductSection = ({ seller, product }) => {
    return (
      <div>
        <Row>
          <Col>
            <Row className="text-muted justify-content-between align-items-center">
              <Col>
                <Stack gap={1}>
                  <strong>{seller.name}</strong>
                  <strong className="text-primary">
                    {product.productName}
                  </strong>
                  <strong>{product.specTitle || ""}</strong>
                </Stack>
              </Col>
              <Col xs="auto">
                <strong>
                  <FontAwesomeIcon icon={faTimes} /> {product.amount}
                </strong>
              </Col>
            </Row>
          </Col>
          <Col xs="auto">
            <Image
              src={product.images[0]}
              width="80px"
              height="80px"
              style={{ borderRadius: "20px" }}
            />
          </Col>
        </Row>
      </div>
    );
  };

  const RefundButton = () => {
    const [refundPending, setRefundPending] = useState(false);
    const progressRef = useRef();
    const [w, setW] = useState(0);
    const handleRefund = async () => {
      try {
        setRefundPending(true);
        let result = await refundOrder(order._id);
        setOrder(result);
        addToast("成功退款");
      } catch (err) {
        addToast(err.message, { appearance: "error" });
      } finally {
        setRefundPending(false);
      }
    };

    const handleLongPress = (action) => {
      // if (
      //   checkPending ||
      //   gift.status !== process.env["REACT_APP_COUPON_STATUS_CATCH"]
      // )
      //   return;
      if (progressRef.current && action == "cancel") {
        clearInterval(progressRef.current);
        progressRef.current = null;
        setW(0);
        return;
      }
      if (!progressRef.current && action == "start") {
        progressRef.current = setInterval(() => {
          setW((prev) => {
            if (prev < 100) return prev + 1.5;
            handleRefund();
            clearInterval(progressRef.current);
            return prev;
          });
        }, 10);
      }
    };
    return (
      <Button
        variant="outline-danger"
        style={{
          background: `linear-gradient(to right, #dc3545 ${w}%, #FFF ${w}%)`,
          color: `${w > 0 ? "#FFF" : "#dc3545"}`,
          fontWeight: "bold",
        }}
        onTouchStart={() => handleLongPress("start")}
        onTouchEnd={() => handleLongPress("cancel")}
        onMouseDown={() => handleLongPress("start")}
        onMouseUp={() => handleLongPress("cancel")}
        disabled={refundPending}
      >
        {refundPending ? <Spinner animation="border" size="sm" /> : "長按退款"}
      </Button>
    );
  };

  return (
    <Card
      xs={Col}
      className="my-2 order-record"
      style={{ borderRadius: "20px" }}
    >
      <Card.Body>
        <Row className="justify-content-between align-items-center my-1">
          <Col xs="auto">
            <Badge bg={statusBadge.variant}>{statusBadge.title}</Badge>
          </Col>
          <Col xs="auto">
            <Stack direction="horizontal" gap={1}>
              <small className="smallText">ID:{order.tradeNo}</small>
              <FontAwesomeIcon
                icon={faCopy}
                className="text-primary"
                onClick={() => copyClipboard(order.tradeNo)}
              />
            </Stack>
          </Col>
        </Row>

        <Row
          className="text-center justify-content-between"
          style={{ fontSize: "14px", fontWeight: "bold" }}
        >
          <Col xs="auto">
            <small>到期：</small>
            <small>
              {new Date(record.products[0].expiredDate).toLocaleDateString()}
            </small>
          </Col>
          <Col xs="auto">
            <small>購買：</small>
            <small>{new Date(record.createdAt).toLocaleDateString()}</small>
          </Col>
          <Col xs="auto">
            <small>來源：</small>
            <small>
              <PlatformBadge platform={record.sellerData.platform} />
            </small>
          </Col>
        </Row>
        <hr />

        {order.products.map((product, idx) => (
          <ProductSection key={idx} seller={seller} product={product} />
        ))}

        <PriceSection
          // detail={paymentDetail}
          spread={spreadDetail}
          isSpread={baseOn}
          isService={!baseOn}
        />
        <hr />
        {order.status === process.env["REACT_APP_COUPON_STATUS_CATCH"] && (
          <Row className="justify-content-center">
            <Col xs={6} lg={4}>
              <Stack>
                {/* {gift ? (
                  <Button
                    variant="outline-primary"
                    onClick={() => setShowGiftModal(true)}
                  >
                    <FontAwesomeIcon icon={faGift} /> 編輯送禮
                  </Button>
                ) : (
                  <Button
                    // variant="outline-primary"
                    // onClick={() => history.push(`gift/req?addGift=${order._id}`)}
                    onClick={() => setShowGiftModal(true)}
                    disabled={order.reviewID}
                  >
                    <FontAwesomeIcon icon={faGift} /> 送禮
                  </Button>
                )} */}
                <Button onClick={() => handleGiftReq(order)}>送禮</Button>
              </Stack>
            </Col>
            <Col xs={6} lg={4}>
              <Stack>
                <RefundButton />
              </Stack>
            </Col>
          </Row>
        )}

        {shippingStatus >= 0 && (
          <>
            <Row className="text-center my-2">
              {["準備中", "運送中", "已送達"].map((e, idx) => {
                const isLight = idx <= shippingStatus;
                return (
                  <Col key={e}>
                    {e}
                    <ProgressBar animated={true} now={isLight ? 100 : 0} />
                  </Col>
                );
              })}
            </Row>
            <Row>
              <Col className="text-muted">
                <small>預計到達時間：</small>
                {order.shipping.estimatedDeliveryDate ? (
                  <small>
                    {new Date(
                      order.shipping.estimatedDeliveryDate
                    ).toLocaleDateString()}
                  </small>
                ) : (
                  <small>預估中</small>
                )}
              </Col>
            </Row>
          </>
        )}
        <br />
        {/* {baseOn && <OrderRecord record={baseOn} />} */}
        {baseOn && (
          <div>
            <hr />
            <h5>更改前禮物</h5>
            <ProductSection
              seller={baseOn.sellerData}
              product={baseOn.products[0]}
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
